<template>
  <section class="su-reviews">
    <div class="su-reviews__header">
      <component
        :is="titleType"
        class="su-reviews__title"
      >
        <span v-html="titleText" />
      </component>

      <div class="su-reviews__world">
        <p>
          для&nbsp;каждой семьи у&nbsp;нас&nbsp;есть&nbsp;специальные предложения,
          льготы и&nbsp;гарантии<span>. узнайте о&nbsp;них&nbsp;сейчас</span>
        </p>
        <div class="su-reviews__world_countries">
          <img
            src="/v2/school-university/reviews/rus.png"
            alt="Russia"
          >
          <img
            src="/v2/school-university/reviews/bra.png"
            alt="Brazil"
          >
          <img
            src="/v2/school-university/reviews/arg.png"
            alt="Argentina"
          >
          <img
            src="/v2/school-university/reviews/tur.png"
            alt="Turkey"
          >
          <img
            src="/v2/school-university/reviews/indo.png"
            alt="Indonesia"
          >
          <img
            src="/v2/school-university/reviews/indi.png"
            alt="India"
          >
          <img
            src="/v2/school-university/reviews/gb.png"
            alt="Great britain"
          >
          <img
            src="/v2/school-university/reviews/kaz.png"
            alt="Kazakhstan"
          >

          <span>и еще более +30&nbsp;стран</span>
        </div>
        <div class="su-reviews__world_marks">
          <div>
            <b>4.9</b>
            <img
              src="/v2/school-university/reviews/ya.svg"
              alt="ya"
            >
            <span>Яндекс</span>
          </div>
          <div>
            <b>4.7</b>
            <img
              src="/v2/school-university/reviews/google.svg"
              alt="ya"
            >
            <span>Google</span>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="['su-reviews__content', `su-reviews__content--${showTimes}`, {
        'su-reviews__content--all': showAll
      }]"
    >
      <div
        v-for="(review, index) in reviews"
        :key="index"
        class="su-reviews__review review pointer"
        @click="openVideoModal(review.video)"
      >
        <div class="review__top">
          <img
            :src="`/v2/school-university/reviews/${review.img}`"
            :alt="review.name"
            class="review__image"
          >

          <button
            class="m-btn"
            @click.stop="openVideoModal(review.video)"
          >
            <img
              src="/v2/school-university/reviews/play.svg"
              alt="play"
            >
          </button>
        </div>

        <p
          class="review__text"
          v-html="review.text"
        />

        <div class="review__bottom">
          <p class="review__name">
            {{ review.name }}
          </p>
          <p class="review__about">
            {{ review.about }}
          </p>
        </div>
      </div>
    </div>

    <button
      v-if="!showAll"
      class="m-btn su-reviews__show-all"
      @click="showMore"
    >
      открыть больше отзывов
    </button>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SuReviews',
  props: {
    titleText: {
      type: String,
      default: 'нас выбирают родители и дети <span>со&nbsp;всего мира</span>',
    },
    titleType: {
      type: String,
      default: 'h2',
    },
  },
  data: () => ({
    showTimes: 1,
    /* eslint-disable max-len,vue/max-len */
    items: [
      {
        name: 'Мария Эрмель',
        about: 'мама двух учениц ',
        text: 'Моя&nbsp;дочь постоянно испытывала стресс в&nbsp;обычной школе и&nbsp;каждый раз&nbsp;возникали сложности, так&nbsp;как&nbsp;ей нужно было часто пить терапию. Сейчас откровенно могу сказать, что&nbsp;онлайн-школа «Синергия» спасла моей дочери жизнь, как&nbsp;только она перешла к&nbsp;вам, то&nbsp;уже&nbsp;через&nbsp;месяц мы&nbsp;перестали проходить терапию',
        img: '1.png',
        video: 'https://www.youtube.com/embed/vNS2eP2ir-o?si=ERs-xO7pC_emq_yc',
      },
      {
        name: 'Арина',
        about: 'ученица 5 класса',
        text: 'Мы очень любим путешествовать и, если учиться в обычной школе, у меня бы этого просто не вышло',
        img: '2.png',
        video: 'https://www.youtube.com/embed/yDtxNwG2x28?si=8Tb4L-07NbpOr6fB',
      },
      {
        name: 'Ольга Дё',
        about: 'мама ученицы 9 класса ',
        text: 'Синергия» избавила мою дочь от стресса и постоянных антибиотиков',
        img: '3.png',
        video: 'https://www.youtube.com/embed/XzZLNKMPeOw?si=_Q84mWjkkc8UWw2k',
      },
      {
        name: 'Сергунин Егор',
        about: 'ученик 11А класса',
        text: 'Являюсь профессиональным спортсменом по&nbsp;плаванию, сборная Краснодарского края. Учась в&nbsp;обычной школе офлайн, я&nbsp;бы ничего не&nbsp;успевал',
        img: '4.png',
        video: '/v2/new-year/feedbacks/feedback-1.mp4',
      },
      {
        name: 'Макарова Луиза',
        about: 'ученица 9Г класса',
        text: 'В&nbsp;прошлой школе меня обижали, ребята не&nbsp;очень ко&nbsp;мне&nbsp;относились. Я&nbsp;очень рада что&nbsp;у&nbsp;меня сейчас такой хороший, дружный класс',
        img: '5.png',
        video: '/v2/new-year/feedbacks/feedback-4.mp4',
      },
      {
        name: 'Варданян Зинаида',
        about: 'ученица 8А класса',
        text: 'После&nbsp;перехода в&nbsp;онлайн-школу, у&nbsp;меня появилось гораздо больше времени. Я&nbsp;могу не&nbsp;бежать после&nbsp;школы домой, чтобы&nbsp;быстро переодеться и&nbsp;побежать на&nbsp;тренировку',
        img: '6.png',
        video: '/v2/new-year/feedbacks/feedback-3.mp4',
      },
      {
        name: 'Оля',
        about: 'ученица 10А класса',
        text: 'Учителя помогали нам&nbsp;понять предмет, а, самое главной, полюбить&nbsp;его. Уроки были не&nbsp;только информативные, но&nbsp;еще&nbsp;и&nbsp;интересные, учителя старались найти подход к&nbsp;каждому ученику, стараясь объяснить так, чтобы&nbsp;все&nbsp;поняли',
        img: '7.png',
        video: '/v2/school-university/reviews/Оля 10А.mp4',
      },
      {
        name: 'Яна Куцанова',
        about: 'ученик 9Б класса',
        text: 'Синергия это огромная и дружная семья, здесь тебя всегда поддержат и направят на правильный путь. Учителя школы просто лучшие люди планеты',
        img: '8.png',
        video: '/v2/school-university/reviews/ученица 9 класс.MOV',
      },
      {
        name: 'Арина',
        about: 'ученица 9В класса',
        text: 'Мне&nbsp;очень удобно учиться онлайн так&nbsp;как&nbsp;я занимаюсь профессиональным спортом и&nbsp;часто в&nbsp;разъездах',
        img: '9.png',
        video: 'https://www.youtube.com/embed/HRkeCuuzz3s?si=6G8WTw2zdi5UjI_A',
      },
      {
        name: 'Александра Козырева',
        about: 'мама ученика 7Б класса',
        text: 'За&nbsp;2&nbsp;дня&nbsp;нас зачислили дистанционно из&nbsp;Ирландии…через несколько недель дочка рассказала, что&nbsp;начала понимать алгебру и&nbsp;геометрию',
        img: '10.png',
        video: 'https://www.youtube.com/embed/0oYUcMPA_EQ?si=uismxE5vyXi4s0EF',
      },
      {
        name: 'Ольга Бадави',
        about: 'мама ученика 6Б класса',
        text: 'Мы путешествуем по миру... посоветовали онлайн‑школу Синергия. Я&nbsp;очень довольна качеством обучения',
        img: '11.png',
        video: '/v2/videos/Отзыв семьи, путешествующей.mp4',
      },
      {
        name: 'Галия Симонцева',
        about: 'мама ученика 5А класса',
        text: 'Там&nbsp;такая замечательная платформа, даже&nbsp;если мой&nbsp;сын пропустил урок, он&nbsp;может самостоятельно посмотреть его&nbsp;и&nbsp;нагнать то, что&nbsp;пропустил',
        img: '12.png',
        video: 'https://www.youtube.com/embed/6oq0WecI55U?si=JEEfpgMQ8p_IH0FV',
      },
      {
        name: 'Арина',
        about: 'ученица 11А класса',
        text: 'Больше всего мне&nbsp;понравился подход учителей к&nbsp;образовательному процессу, то&nbsp;как&nbsp;они объясняли и&nbsp;поддерживали нас, благодаря им&nbsp;я&nbsp;смогла минимизировать стресс перед&nbsp;экзаменом и&nbsp;пережить это&nbsp;жизненное испытание',
        img: '13.png',
        video: '/v2/school-university/reviews/Захарова Алиса 11 класс 2023.mp4',
      },
      {
        name: 'Марина',
        about: 'мама ученика 7А класса',
        text: 'Мой&nbsp;сын&nbsp;профессиональный спортсмен. Я&nbsp;с&nbsp;большим удовольствием могу сказать, что&nbsp;мы&nbsp;получили очень много плюсов от&nbsp;поступления в&nbsp;онлайн‑школу',
        img: '14.png',
        video: 'https://www.youtube.com/embed/ROoY-Yv4rPQ?si=9Cuovbwq_9JOBrjE',
      },
      {
        name: 'Елена Сергеевна',
        about: 'мама ученика 9В класса',
        text: 'Огромная благодарность педагогическому составу за&nbsp;профессионализм и&nbsp;за&nbsp;нахождение тех&nbsp;самых ниточек, которые они&nbsp;прокладывали, распускали именно в&nbsp;адрес каждого ребенка и&nbsp;находили подход к&nbsp;каждому ребенку, это&nbsp;очень круто, спасибо вам&nbsp;огромное',
        img: '15.png',
        video: 'https://www.youtube.com/embed/vexA8kXbe9M?si=icuzdvzQfaZj_uJF',
      },
      {
        name: 'Валерия Фомина',
        about: 'ученица 11А класса',
        text: 'Больше всего понравился подход учителей к&nbsp;ученикам, их&nbsp;отношение, я&nbsp;видела от&nbsp;них&nbsp;поддержку, веру в&nbsp;нас&nbsp;и&nbsp;это&nbsp;безумно ценно',
        img: '16.png',
        video: '/v2/school-university/reviews/Фомина Валерия 11 класс 2023.mp4',
      },
      {
        name: 'Алла Цароева',
        about: 'мама ученика 7А класса',
        text: 'Долго искала своему сыну такую школу где&nbsp;будет индивидуальный подход',
        img: '17.png',
        video: 'https://www.youtube.com/embed/yhUeEJe_uN0?si=KJjU2uk1kKGLmh4O',
      },
    ],
    /* eslint-enable  */
  }),
  computed: {
    ...mapGetters({
      windowSize: 'getWindowSize',
    }),
    showCountBySize() {
      const suMobile = this.windowSize <= 768;
      const suTablet = this.windowSize <= 1200;
      let showCount = 8;

      if (suTablet) showCount = 4;
      if (suMobile) showCount = 3;

      return showCount;
    },
    reviews() {
      if (!this.showAll) {
        return this.items.slice(0, this.showCountBySize * this.showTimes);
      }

      return this.items;
    },
    showAll() {
      return this.showTimes * this.showCountBySize >= this.items.length;
    },
  },
  methods: {
    ...mapActions('modals', {
      openVideoModal: 'openVideo',
    }),
    showMore() {
      if (this.windowSize >= 1200) {
        this.showTimes = 10;
      } else {
        this.showTimes += 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">

.su-reviews {
  padding: 2.5rem;
  background-image: url("/v2/school-university/reviews/bg.webp");
  background-size: cover;
  background-position: top center;
  border-radius: calc(var(--scale) * 60);

  @include media-down($size-tablet) {
    border-radius: 3.2rem;
    padding: 2rem 0.8rem 0.8rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include media-down($size-tablet) {
      margin-inline: 0.8rem;
    }

    @include media-down($size-mobile) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  .su-reviews__title {
    max-width: calc(var(--scale) * 870);

    font-style: normal;
    font-weight: 500;
    font-size: 7rem;
    line-height: 90%;
    letter-spacing: -0.06em;
    color: #F9F9F9;

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 334);
      font-size: 4.4rem;
    }

    span {
      ::v-deep span {
        color: rgba(#ffffff, 0.5);
      }
    }
  }

  .su-reviews__world {
    max-width: calc(var(--scale) * 715);

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 334);
    }

    @include media-down($size-mobile) {
      width: 100%;
    }

    p {
      margin-bottom: 1.25rem;

      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-size: 2rem;
        line-height: 110%;
        letter-spacing: -0.02em;

        span {
          display: none;
        }
      }
    }

    &_countries {
      display: flex;
      align-items: center;

      img {
        position: relative;
        width: 4rem;
        height: 4rem;

        border: 4px solid #FFA820;
        border-radius: 100px;
        margin-right: calc(-1  * var(--scale) * 10);

        @include media-down($size-tablet) {
          width: 3.8rem;
          height: 3.8rem;
          border-width: 2px;
        }

        &:nth-of-type(1) { z-index: 7; }
        &:nth-of-type(2) { z-index: 6; }
        &:nth-of-type(3) { z-index: 5; }
        &:nth-of-type(4) { z-index: 4; }
        &:nth-of-type(5) { z-index: 3; }
        &:nth-of-type(6) { z-index: 2; }
        &:nth-of-type(7) { z-index: 1; }
        &:nth-of-type(8) { z-index: 0; }
      }

      span {
        margin-left: calc(var(--scale) * 25);

        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 130%;
        color: #FFFFFF;

        @include media-down($size-tablet) {
          margin-left: 1.4rem;
          font-size: 1.4rem;
        }
      }
    }

    &_marks {
      margin-top: calc(var(--scale) * 34);
      display: flex;
      gap: calc(var(--scale) * 10);

      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 16);
        gap: calc(var(--scale) * 4);
      }

      @include media-down($size-mobile) {
        margin-top: calc(var(--scale) * 20);
        margin-left: calc(-1 * var(--scale) * 8);
      }

      div {
        width: calc(var(--scale) * 218);
        height: calc(var(--scale) * 80);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc(var(--scale) * 8);
        padding-inline: calc(var(--scale) * 16);

        background-color: #FFFFFF;
        box-shadow:
          inset calc(var(--scale) * 8) calc(var(--scale) * 8) calc(var(--scale) * 30) rgba(87, 106, 135, 0.02),
          inset calc(var(--scale) * 2) calc(var(--scale) * 2) calc(var(--scale) * 10) rgba(87, 106, 135, 0.02);
        border-radius: calc(var(--scale) * 20);

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 175);
          height: calc(var(--scale) * 56);
          gap: calc(var(--scale) * 6);
          padding-inline: calc(var(--scale) * 12);
          border-radius: calc(var(--scale) * 16);
        }

        @include media-down($size-mobile) {
          width: calc(var(--scale) * 173);
          flex-shrink: 0;
        }

        b {
          font-weight: 800;
          font-size: calc(var(--scale) * 50);
          line-height: 100%;
          letter-spacing: -0.06em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #242424;

          @include media-down($size-tablet) {
            font-size: calc(var(--scale) * 40);
          }
        }

        img {
          width: calc(var(--scale) * 36);

          @include media-down($size-tablet) {
            width: calc(var(--scale) * 28);
          }
        }

        span {
          font-family: 'TildaSans', sans-serif;
          font-weight: 500;
          font-size: calc(var(--scale) * 20);
          line-height: 100%;
          letter-spacing: -0.01em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #919191;

          @include media-down($size-tablet) {
            font-size: calc(var(--scale) * 14);
          }
        }
      }
    }
  }

  .su-reviews__content {
    margin-top: calc(var(--scale) * 69);

    display: flex;
    height: calc(var(--scale) * 887);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: calc(var(--scale) * 5);

    @include media-down($size-tablet) {
      height: calc(var(--scale) * 590);
      margin-top: 8rem;
      gap: 0.8rem;
    }

    @include media-down($size-mobile) {
      margin-top: 4rem;
    }

    &--all {
      height: calc(var(--scale) * 1850);

      @include media-down($size-tablet) {
        height: calc(var(--scale) * 2400);
      }
    }

    @include media-down($size-tablet) {
      &--2 {
        height: calc(var(--scale) * 1150);
      }

      &--3 {
        height: calc(var(--scale) * 1660);
      }

      &--4 {
        height: calc(var(--scale) * 2240);
      }
    }

    @include media-down($size-mobile) {
      flex-wrap: nowrap;
      height: fit-content;
    }
  }

  .review {
    width: calc(var(--scale) * 434);
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    background-color: #F9F9F9;
    border-radius: 2.5rem;

    &:hover {
      .review__text {
        color: #02120F;
      }
    }

    // https://doka.guide/recipes/masonry/
    &:nth-child(4n + 1) {
      order: 1;
    }

    &:nth-child(4n + 2) {
      order: 2;
    }
    &:nth-child(2) {
      margin-top: 5rem;

      @include media-down($size-tablet) {
        margin-top: 0;
      }
    }

    &:nth-child(4n + 3) {
      order: 3;
    }
    &:nth-child(3) {
      margin-top: 2.5rem;

      @include media-down($size-tablet) {
        margin-top: 0;
      }
    }

    &:nth-child(4n) {
      order: 4;
    }

    &:last-of-type {
      order: 4;
    }

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 356);
      padding: 1.6rem;
      gap: 2rem;
    }

    @include media-down($size-mobile) {
      order: 0 !important;
    }

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      button {
        display: grid;
        place-content: center;
        width: calc(var(--scale) * 50);
        height: calc(var(--scale) * 50);
        background-color: #5237E6;
        border-radius: 50%;
        padding: calc(var(--scale) * 14);
        transition: 200ms;

        &:hover {
          background-color: #846EFB;
        }

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 32);
          height: calc(var(--scale) * 32);
        }

        img {
          margin-left: calc(var(--scale) * 4);
          width: calc(var(--scale) * 22);
          height: calc(var(--scale) * 22);

          @include media-down($size-tablet) {
            width: calc(var(--scale) * 14);
            height: calc(var(--scale) * 14);
          }
        }
      }
    }

    &__image {
      width: 6.25rem;
      height: 6.25rem;

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 64);
        height: calc(var(--scale) * 64);
        border-radius: 2rem;
      }
    }

    &__text {
      transition: 200ms;
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: 1.6rem;
        letter-spacing: -0.02em;
      }
    }

    &__name {
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: 2rem;
        line-height: 110%;
        letter-spacing: -0.02em;
      }
    }

    &__about {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 130%;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: 1.4rem;
      }
    }
  }

  .su-reviews__show-all {
    display: block;
    margin: 2.5rem auto 0;

    padding: calc(var(--scale) * 44) 3.5rem;

    border: 1px solid #FFFFFF;
    border-radius: 2.5rem;

    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 110%;
    color: #FFFFFF;

    @media (hover: hover) {
      &:hover {
        background-color: #FFF0D1;
        color: #FF5319;
      }
    }

    @include media-down($size-tablet) {
      width: 100%;
      margin-top: 4rem;
      padding: 2.9rem 0;
      background-color: rgba(#ffffff, 0.01);

      font-weight: 500;
      font-size: 1.8rem;
    }

    @include media-down($size-mobile) {
      margin-top: 2rem;
    }
  }
}

</style>
