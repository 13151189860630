<template>
  <section class="su-faq">
    <div class="su-faq__info">
      <h3>собрали для вас ответы на частые <br>и волнующие вопросы</h3>
      <p>
        не&nbsp;нашли ответа на&nbsp;ваш&nbsp;вопрос?
        <span>задайте его&nbsp;специалисту из&nbsp;отдела&nbsp;заботы</span>
      </p>
      <a
        href="https://t.me/onlineschool_university_bot"
        target="_blank"
      >
        <span>задать вопрос</span>
        <img
          src="/v2/school-university/faq/tg.svg"
          alt="telegram"
        >
      </a>
    </div>

    <div class="su-faq__questions">
      <div
        v-for="(item, index) in questions"
        :key="index"
        :class="['su-faq__question', { 'su-faq__question--active': item.open }] "
      >
        <button
          class="m-btn su-faq__question_btn"
          @click="item.open = !item.open"
        >
          <img
            src="/v2/school-university/faq/arrow.svg"
            alt="arrow"
          >
          <span v-html="item.question" />
        </button>

        <div
          :ref="`answer-${index}`"
          class="su-faq__question_answer"
          :style="{
            height: item.open ? getHeight(index) : 0,
          }"
          v-html="item.answer"
        />
        <!--'margin-top': !openedItems.includes(index) ? '-10px' : 0,-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SuFaq',
  data: () => ({
    /* eslint-disable max-len,vue/max-len */
    questions: [
      {
        open: false,
        question: 'какие документы нужны для&nbsp;поступления?',
        answer: 'Базовый пакет документов включает: паспорт родителя и&nbsp;ребенка, свидетельство о&nbsp;рождении ребенка, СНИЛС, личная карта обучающегося и&nbsp;аттестат об&nbsp;окончании 9-го класса (для&nbsp;поступающих в&nbsp;10–11&nbsp;класс). Оставьте заявку, и&nbsp;мы&nbsp;подробно расскажем, какие документы потребуются и&nbsp;как&nbsp;их собрать.',
      },
      {
        open: false,
        question: 'какие различия между&nbsp;заочной и&nbsp;семейной формой обучения?',
        answer: `
        <a href="https://youtu.be/UsoYSi9jdK8?si=_BNyEk4wVNy2vDNj" target="_blank">Смотрите видео</a>
        с&nbsp;директором онлайн‑школы «Синергия» о&nbsp;плюсах и&nbsp;минусах семейной и&nbsp;заочной формы обучения
        <br><br>
        Заочная форма подразумевает, что&nbsp;ребенок зачислен в&nbsp;нашу школу с&nbsp;первого дня&nbsp;обучения, переход к&nbsp;нам&nbsp;на&nbsp;«заочку» юридически эквивалентен переходу ребенка в&nbsp;другую классическую школу. В&nbsp;9&nbsp;и&nbsp;11&nbsp;классах ученикам заочной форме необходимо будет приехать на&nbsp;ОГЭ&nbsp;или&nbsp;ЕГЭ&nbsp;в&nbsp;Москву. Наши ученики-заочники получают московский аттестат&nbsp;гос. образца Университета Синергия.
        <br><br>
        Семейная форма обучения подразумевает, что&nbsp;ребенок переведен на&nbsp;семейное обучение и&nbsp;за&nbsp;его обучение отвечает семья. Чтобы&nbsp;не&nbsp;обучать ребенка ежедневно самостоятельно, родители могут перевести ребенка на&nbsp;семейную форму обучения, но&nbsp;доверить его&nbsp;обучение лицензированной удобной онлайн-школе с&nbsp;сильными преподавателями и&nbsp;проверенными материалами по&nbsp;ФГОС.
        <br><br>
        По&nbsp;закону родители могут сами обучать ребенка, но&nbsp;не&nbsp;могут его&nbsp;аттестовать, поэтому на&nbsp;время прохождения аттестации ребенку, который находится на&nbsp;семейном обучении, необходимо прикрепиться к&nbsp;школе: прикрепиться можно к&nbsp;нашей онлайн-школе или&nbsp;к&nbsp;любой другой школе, например, по&nbsp;месту жительства. Выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены.
        <br><br>
        На&nbsp;любой форме обучения в&nbsp;онлайн-школе вы&nbsp;можете посещать занятия, вам&nbsp;открыт доступ ко&nbsp;материалам на&nbsp;платформе, вам&nbsp;также&nbsp;будет доступен весь сервис согласно выбранному тарифу`,
      },
      {
        open: false,
        question: 'как&nbsp;перевести ребенка на&nbsp;семейное обучение?',
        answer: `Сперва предупредите директора вашей школы о&nbsp;переходе. Затем напишите уведомление и&nbsp;оповестите Департамент образования вашего города или&nbsp;округа о&nbsp;вашем решении. После&nbsp;определитесь со&nbsp;школой, куда вы&nbsp;прикрепитесь, чтобы&nbsp;проходить аттестации и&nbsp;экзамены. Прикрепиться можно к&nbsp;онлайн-школе «Синергия», мы&nbsp;с&nbsp;радостью вам&nbsp;в&nbsp;этом поможем.
        <br><br>
        Также&nbsp;в&nbsp;нашей школе можно учиться заочно, в&nbsp;этом случае вам&nbsp;не&nbsp;нужно будет переводиться на&nbsp;семейное обучение, поступить на&nbsp;заочную форму также&nbsp;просто, как&nbsp;сменить школу, а&nbsp;мы&nbsp;позаботимся, чтобы&nbsp;поступление прошло быстро и&nbsp;комфортно.`,
      },
      {
        open: false,
        question: 'как проходит обучение?',
        answer: `В&nbsp;зависимости от&nbsp;выбранного тарифа обучения, ребенок может учиться в&nbsp;мини-группах (связь с&nbsp;учителем через&nbsp;камеру и&nbsp;микрофон, максимальное внимание вашему ребенку) или&nbsp;на&nbsp;вебинрах (связь с&nbsp;учителем через&nbsp;чат), смотреть уроки можно в&nbsp;записи, во&nbsp;время и&nbsp;после урока ученику доступна интерактивная платформа с&nbsp;тренажерами, конспектами и&nbsp;домашними заданиями.
        <br><br>
        С&nbsp;каждым учеником работает целая команда заботы: классный руководитель поможет адаптироваться в&nbsp;школе и&nbsp;классе, с&nbsp;радостью решит любые организационные вопросы; наставник подскажет по&nbsp;предметным вопросам и&nbsp;поможет с&nbsp;домашками; также&nbsp;в&nbsp;нашей школе работают тьютор, психолог и&nbsp;профориентолог`,
      },
      {
        open: false,
        question: 'как проходят экзамены в&nbsp;онлайн‑школе?',
        answer: `Полностью онлайн для&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классов:
        <br><br>
        Для&nbsp;учеников (на&nbsp;любой форме обучения) 5–8&nbsp;и&nbsp;10&nbsp;классов аттестация проходит онлайн. Аттестация в&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классах&nbsp;— это&nbsp;годовые контрольные работы по&nbsp;всем школьным предметам, которые проходят в&nbsp;онлайн-формате с&nbsp;включенными камерами и&nbsp;микрофонами под&nbsp;контролем учителя.
        <br><br>
        Очно для&nbsp;9&nbsp;и&nbsp;11&nbsp;классов:
        <br><br>
        Для&nbsp;выпускных 9&nbsp;и&nbsp;11&nbsp;классов аттестация проходит в&nbsp;виде государственных экзаменов (ОГЭ&nbsp;и&nbsp;ЕГЭ). Ученикам нашей школы, которые обучаются на&nbsp;заочной форме, необходимо будет приехать на&nbsp;экзамены в&nbsp;Москву. Выпускники заочной формы в&nbsp;нашей школе получат московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия.
        <br><br>
        Те, кто&nbsp;обучается у&nbsp;нас&nbsp;на&nbsp;семейной форме с&nbsp;прикреплением, могут выбрать: сдавать экзамены от&nbsp;нашей школы или&nbsp;от&nbsp;школы по&nbsp;выбору (часто это&nbsp;школа по&nbsp;месту жительства), куда нужно будет прикрепиться на&nbsp;период экзаменов. Важно, что&nbsp;при&nbsp;этом выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены. Если&nbsp;выпускник сдает экзамены в&nbsp;Москве от&nbsp;нашей школы, то&nbsp;получит московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия, если&nbsp;в&nbsp;другой школе, то&nbsp;аттестат той&nbsp;школы.`,
      },
      {
        open: false,
        question: 'можно ли оплатить обучение частями или в рассрочку?',
        answer: `мы предоставляем возможность клиентам оплатить обучение в рассрочку без процентов (6/12/24/36 месяцев)
        <br><br>
        Также, для удобства, есть возможность разделить стоимость обучения на 3 платежа или платить частями при оплате услугой <p class="split"><span>Яндекс</span>.<span>Сплит</span></p>
        <br><br>
        для&nbsp;подробной информации о&nbsp;всех скидках и&nbsp;их&nbsp;применению вы&nbsp;можете обратиться в&nbsp;нашу службу заботы по&nbsp;телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или&nbsp;написать специалисту в&nbsp;<a href="https://t.me/onlineschool_university_bot" target="_blank">telegram</a> или&nbsp;<a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в&nbsp;любое время с&nbsp;8:00–20:00&nbsp;без&nbsp;выходных`,
      },
      {
        open: false,
        question: 'как оформить налоговый вычет?',
        answer: 'да, конечно, для консультации и по вопросам, связанным с оформлением, вы можете обратиться в нашу службу заботы по телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или написать специалисту в <a href="https://t.me/onlineschool_university_bot" target="_blank">telegram</a> или <a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в любое время с 8:00-20:00 без выходных',
      },
      {
        open: false,
        question: 'могу ли я воспользоваться материнским капиталом?',
        answer: 'да, конечно, для консультации и по вопросам, связанным с оформлением, вы можете обратиться в нашу службу заботы по телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a> или написать специалисту в <a href="https://t.me/onlineschool_university_bot" target="_blank">telegram</a> или <a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в любое время с 8:00-20:00 без выходных',
      },
      {
        open: false,
        question: 'есть ли социальные льготы/скидки?',
        answer: `в&nbsp;нашей школе действуют скидки и&nbsp;льготные условия для&nbsp;детей-инвалидов, многодетным семьям, участникам СВО&nbsp;и&nbsp;многих других категорий граждан
        <br><br>
        так&nbsp;же&nbsp;действует постоянная скидка при&nbsp;единовременной оплате обучения до&nbsp;20% и&nbsp;скидки в&nbsp;зависимости от&nbsp;месяца поступления в&nbsp;Школу (от&nbsp;10&nbsp;до&nbsp;75%). Скидка на&nbsp;обучение второго ребенка (до&nbsp;20%)
        <br><br>
        по&nbsp;мимо этого&nbsp;у&nbsp;нас часто проводятся сезонные и&nbsp;промо акции
        <br><br>
        для&nbsp;подробной информации о&nbsp;всех скидках и&nbsp;их&nbsp;применению вы&nbsp;можете обратиться в&nbsp;нашу службу заботы по&nbsp;телефону <a href="tel:+74952804009" target="_blank">+7&nbsp;495&nbsp;280-40-09</a>&nbsp;или&nbsp;написать специалисту в&nbsp;<a href="https://t.me/onlineschool_university_bot" target="_blank">telegram</a> или&nbsp;<a href="https://wa.me/79686286327" target="_blank">whatsapp</a>, в&nbsp;любое время с&nbsp;8:00–20:00&nbsp;без&nbsp;выходных`,
      },
    ],
    /* eslint-enable */
  }),
  methods: {
    getHeight(index) {
      if (process.browser) {
        // ждем отрисовки DOM
        const [elem] = this.$refs[`answer-${index}`];
        return `${elem?.scrollHeight || 0}px`;
      }

      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
$su-tablet: 1200px;
$su-mobile: 767px;

.su-faq {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2.5rem;

  background-color: #F9F9F9;
  border-radius: 60px;

  @include media-down($su-tablet) {
    padding: 2rem 1.6rem;
    background-color: #FFFFFF;
    border-radius: 3.2rem;
  }

  @include media-down($su-mobile) {
    flex-direction: column-reverse;
    position: relative;
    padding: calc(var(--scale) * 236) 1.6rem 1.6rem;
  }

  &__info {
    width: calc(var(--scale) * 560);
    flex-shrink: 0;

    @include media-down($su-tablet) {
      width: 33.4rem;
    }

    @include media-down($su-mobile) {
      width: 100%;
    }

    h3 {
      margin-bottom: 1.25rem;

      font-style: normal;
      font-weight: 500;
      font-size: 4rem;
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($su-tablet) {
        margin-bottom: 4rem;
        line-height: 110%;
        letter-spacing: -0.04em;
      }

      @include media-down($su-mobile) {
        position: absolute;
        left: 1.6rem;
        top: 2rem;
      }
    }

    p {
      margin-bottom: 1.25rem;

      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 110%;
      color: #02120F;

      @include media-down($su-tablet) {
        margin-bottom: 2rem;
        font-size: 1.8rem;
      }

      @include media-down($su-mobile) {
        margin-top: 4rem;
        margin-bottom: 0;
      }

      span {
        color: #A7ABAC;

        @include media-down($su-tablet) {
          display: block;
          padding-top: 1rem;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      width: calc(var(--scale) * 299);
      height: calc(var(--scale) * 72);

      background-color: #2193CC;
      border-radius: calc(var(--scale) * 24);

      @include media-down($su-tablet) {
        width: 24rem;
        height: 7.2rem;
        border-radius: 2.4rem;
      }

      @include media-down($su-mobile) {
        margin-top: 2rem;
        width: 100%;
      }

      &:hover span {
        text-decoration: underline;
      }

      span {
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #FFFFFF;

        @include media-down($su-tablet) {
          font-size: 2rem;
          line-height: 110%;
          letter-spacing: -0.02em;
        }
      }

      img {
        width: calc(var(--scale) * 44);
      }
    }
  }

  &__questions {
    width: calc(var(--scale) * 870);

    @include media-down($su-tablet) {
      width: calc(var(--scale) * 348);
    }

    @include media-down($su-mobile) {
      width: 100%;
    }
  }

  &__question {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #DADCDB;

    @include media-down($su-tablet) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    &:first-child {
      padding-top: calc(var(--scale) * 16);
    }

    &:last-child {
      padding-bottom: calc(var(--scale) * 16);
      border-bottom: none;
    }

    &_btn {
      display: flex;
      align-items: center;
      gap: 1rem;

      @include media-down($su-tablet) {
        gap: 1.2rem;
      }

      img {
        height: 12px;
        width: 12px;
        object-fit: cover;
        transition: 300ms;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #02120F;

        @include media-down($su-tablet) {
          font-size: 1.8rem;
          text-align: left;
        }
      }
    }

    &_answer {
      padding-top: calc(var(--scale) * 10);
      padding-left: calc(12px + 1rem);
      transition: 300ms;
      overflow: hidden;

      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #02120F;

      @include media-down($su-tablet) {
        font-size: 1.6rem;
        letter-spacing: -0.02em;
      }

      ::v-deep a {
        text-decoration-line: underline;
        color: #5237E6;

        &:hover {
          text-decoration: none;
        }
      }

      ::v-deep .split {
        display: inline-block;

        span:first-child {
          color: #FF5319;
        }

        span:last-child {
          color: #04795F;
        }
      }
    }

    &--active img {
      transform: rotate(180deg);
    }
  }
}
</style>
