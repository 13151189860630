<template>
  <section class="su-form">
    <div class="su-title">
      <h2>давайте учиться вместе</h2>
    </div>

    <div class="su-form__content">
      <div class="su-form__info">
        <div class="su-form__info_user">
          <img
            src="/v2/school-university/form/user.png"
            alt="Наталья Столбова"
            class="su-form__info_user_img"
          >
          <p class="su-form__info_user_name">
            Наталья Столбова
            <span>руководитель отдела заботы</span>
          </p>
          <div class="su-form__info_user_cheap">
            <span>8:00-20:00</span>
            <span>без выходных</span>
          </div>
        </div>
        <h4 class="su-form__info_title">
          начните свой путь к&nbsp;успеху
          <span>в&nbsp;школьном университете</span>
          <b>с нами</b>
        </h4>
        <p class="su-form__info_text">
          оставьте заявку&nbsp;отделу заботы&nbsp;— мы&nbsp;бесплатно проведем профориентацию
          и&nbsp;поможем подобрать подходящее направление для&nbsp;ребенка,
          а&nbsp;также&nbsp;ответим на&nbsp;любые вопросы
        </p>

        <div class="su-form__info_soc">
          <p>или напишите нам в чат</p>
          <div>
            <a
              href="https://wa.me/79686286327"
              target="_blank"
            >
              <img
                src="/v2/school-university/footer/wa.png"
                alt="whatsapp"
              >
            </a>
            <a
              href="https://t.me/onlineschool_university_bot"
              target="_blank"
            >
              <img
                src="/v2/school-university/footer/tg.png"
                alt="telegram"
              >
            </a>
            <a
              href="mailto:nstolbova@synergy.ru"
              target="_blank"
            >
              <img
                src="/v2/school-university/footer/email.png"
                alt="email"
              >
            </a>
          </div>
        </div>
      </div>

      <div class="su-form__form">
        <div class="su-form__form_fields">
          <form-name-field
            ref="nameField"
            v-model="nameInput"
            placeholder="как вас зовут?"
            class="su-form__form_field"
            round-border
          />
          <form-phone-field
            ref="phoneField"
            class="su-form__form_field"
            :errors-list="submitError"
            :error="validForm === false"
            placeholder="ваш номер телефона"
            round-border
            @updatePhone="phoneInput = $event"
          />
          <form-email-field
            ref="emailField"
            v-model="emailInput"
            class="su-form__form_field"
            :submit-error="submitError"
            placeholder="ваша почта"
            round-border
            @clearSubmitError="clearSubmitError"
          />
        </div>

        <!-- eslint-disable vue/multiline-html-element-content-newline -->
        <p class="su-form__form_privacy">
          нажимая на кнопку, вы соглашаетесь
          с&nbsp;<nuxt-link
            to="/privacy"
            target="_blank"
          >условиями обработки данных</nuxt-link>
          в&nbsp;соответствии
          с&nbsp;<nuxt-link
            to="/privacy"
            target="_blank"
          >политикой конфиденциальности</nuxt-link>
        </p>
        <!-- eslint-enable -->

        <p
          v-if="!validForm"
          class="su-form__form_error"
        >
          *упс, мы обнаружили ошибку, проверьте пожалуйста данные еще раз
        </p>

        <button
          class="m-btn su-form__form_button"
          @click="submit"
        >
          узнать подробнее
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
});

export default {
  name: 'SuForm',
  components: {
    FormNameField,
    FormEmailField,
    FormPhoneField,
  },
  mixins: [sendLeadMixin],
  methods: {
    onSubmit() {
      this.$router.push('/thanks-shus');
    },
  },
};
</script>

<style scoped lang="scss">

.su-form {
  .su-title {
    @include media-down($size-tablet) {
      font-size: 4.4rem;
    }
  }

  &__content {
    margin-top: 2.5rem;
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(2, calc(50% - 1.25rem / 2));
    height: calc(var(--scale) * 720);

    @include media-down($size-tablet) {
      height: 51.2rem;
      grid-template-columns: repeat(2, calc(50% - 0.4rem));
      gap: 0.8rem;
    }

    @include media-down($size-mobile) {
      height: auto;
      grid-template-columns: 100%;
    }
  }

  .su-form__info {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 2.5rem;
    background-color: #F9F9F9;
    border-radius: 60px;

    @include media-down($size-tablet) {
      padding: 1.6rem;
      background-color: #FFFFFF;
      border-radius: 3.2rem;
      gap: 0.8rem;
    }

    @include media-down($size-mobile) {
      height: calc(var(--scale) * 512);
    }

    &_user {
      display: flex;
      gap: 1.25rem;

      @include media-down($size-tablet) {
        flex-wrap: wrap;
        gap: 2rem;
      }

      img {
        width: calc(var(--scale) * 80);

        @include media-down($size-tablet) {
          width: 6.4rem;
        }
      }

      &_name {
        font-style: normal;
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #02120F;

        @include media-down($size-tablet) {
          font-size: 2rem;
          line-height: 110%;
          letter-spacing: -0.02em;
        }

        span {
          display: block;
          font-size: 1rem;
          line-height: 130%;

          @include media-down($size-tablet) {
            font-size: 1.4rem;
          }
        }
      }

      &_cheap {
        margin-left: auto;
        display: flex;
        align-items: flex-start;
        gap: 0.25rem;

        @include media-down($size-tablet) {
          order: -1;
          margin-left: 0;
          width: 100%;
        }

        span {
          padding: calc(var(--scale) * 10) 1.25rem;
          border: 1px solid #CACACB;
          border-radius: 2.5rem;

          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 130%;

          color: #02120F;

          @include media-down($size-tablet) {
            padding: 0.6rem 1.1rem;
            font-size: 1.4rem;
          }
        }
      }
    }

    &_title {
      max-width: calc(var(--scale) * 716);

      font-style: normal;
      font-weight: 500;
      font-size: 4rem;
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        max-width: 100%;
        font-size: 4rem;
        line-height: 110%;
        letter-spacing: -0.04em;

        span {
          display: none;
        }
      }

      b {
        display: none;

        @include media-down($size-tablet) {
          display: inline;
        }
      }
    }

    &_text {
      max-width: calc(var(--scale) * 716);

      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #6B6B6B;

      @include media-down($size-tablet) {
        max-width: 100%;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 110%;
        color: #A7ABAC;
      }
    }

    &_soc {
      margin-top: auto;

      @include media-down($size-tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        margin-bottom: 1.25rem;

        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 130%;
        color: #6B6B6B;

        @include media-down($size-tablet) {
          max-width: 13.7rem;
          margin-bottom: 0;

          font-weight: 500;
          font-size: 1.8rem;
          line-height: 110%;
          color: #02120F;
        }
      }

      div {
        display: flex;
        gap: 0.5rem;

        @include media-down($size-tablet) {
          gap: 0.8rem;
        }

        img {
          width: 5rem;

          @include media-down($size-tablet) {
            width: 5.6rem;
          }
        }
      }
    }
  }

  .su-form__form {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;

    background-image: url("/v2/school-university/form/bg.webp");
    background-size: cover;
    background-color: #5237E6;
    border-radius: calc(var(--scale) * 60);

    @include media-down($size-tablet) {
      padding: 1.6rem 0.8rem 0.8rem;
      border-radius: 3.2rem;
    }

    @include media-down($size-mobile) {
      height: calc(var(--scale) * 512);
    }

    &_fields {
      display: grid;
      gap: 0.5rem;

      @include media-down($size-tablet) {
        padding: 0 0.8rem;
        gap: 0.8rem;
      }

      ::v-deep .su-form__form_field {
        input {
          border: 1px solid transparent;
          height: calc(var(--scale) * 95);
          background-color: rgba(255, 255, 255, 0.2);
          width: 100%;

          font-weight: 500;
          font-size: calc(var(--scale) * 26);
          color: #ffffff;

          @include media-down($size-tablet) {
            font-size: 1.8rem;
            height: 7rem;
          }

          &::placeholder {
            color: rgba(#ffffff, 0.5);
          }

          &:hover::placeholder {
            color: #ffffff;
          }
        }

        .phone-field__wrap {
          border: none;
          background: none;
          height: calc(var(--scale) * 95);

          .phone-field__arrow {
            filter: invert(100%) sepia(0%) saturate(25%) hue-rotate(70deg) brightness(108%) contrast(108%);
          }

          @include media-down($size-tablet) {
            height: 7rem;
          }
        }

        &.has-error input,
        & .phone-field__wrap--error input {
          border-color: #ED131C;
        }

        .error-message,
        .phone-field__error {
          display: none;
        }
      }
    }

    &_privacy {
      margin-top: 2.5rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        padding: 0 0.8rem;

        font-weight: 500;
        font-size: 1.4rem;
      }

      a {
        text-decoration-line: underline;
        color: #FF9C12;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_error {
      margin-top: 0.75rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #ED131C;

      @include media-down($size-tablet) {
        padding: 0 0.8rem;
        font-weight: 500;
        font-size: 1.4rem;
      }
    }

    &_button {
      margin-top: auto;
      height: calc(var(--scale) * 122);
      width: 100%;

      background-color: #FF5319;
      border-radius: 2.5rem;

      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 110%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        height: 8rem;
        box-shadow: 1rem 1rem 5rem rgba(#071539, 0.1), 0.4rem 0.4rem 2rem rgba(#071539, 0.05);
        border-radius: 2.4rem;
        font-size: 1.8rem;
      }

      @media (hover: hover) {
        &:hover {
          background-color: #FF9C12;
        }
      }
    }
  }
}

</style>
